<template>
  <v-app :dark="isAdmin">
    <v-app-bar dark app v-if="!isAdmin">
      <router-link to="/">
        <img src="./assets/clubman-logo.svg" alt="LFA - Logo" width="40" height="40" style="padding-top:10px;" v-if="store.isClubman" />
        <img src="./assets/logo.svg" alt="LFA - Logo" width="40" height="40" style="padding-top:10px;" v-else />
      </router-link>

      <v-divider class="mx-3" inset vertical dark></v-divider>

      <span class="subheading">{{ appTitle }}</span>

      <v-spacer></v-spacer>

      <router-link to="/cart">
        <v-badge right>
          <template v-slot:badge>
            <span>{{ cartItemQty }}</span>
          </template>

          <v-icon medium color="grey lighten-1">
            mdi-cart
          </v-icon>
        </v-badge>
      </router-link>
    </v-app-bar>

    <v-app-bar dark app v-else-if="isLoggedIn()">
      {{ pageTitle }}

      <v-spacer></v-spacer>

      <v-menu dark offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ adminName }}</v-list-item-title>
              <v-list-item-subtitle class="caption">Last login: {{ lastLogin.split(' ')[0] }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="adminDrawer" :mini-variant.sync="miniDrawer" width="300" permanent dark app v-if="isLoggedIn()">
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar size="40" tile>
            <img src="./assets/logo.svg" alt="LFA - Logo" width="40" height="40" style="padding:5px 0;" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>M2M Admin Panel <span class="caption">v2.0</span></v-list-item-title>
          </v-list-item-content>

          <v-btn icon @click.stop="miniDrawer = !miniDrawer">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item to="/admin/dashboard" v-show="can('dashboard')">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-item to="/admin/orders" v-show="can('order_list')">
          <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Orders</v-list-item-title>
        </v-list-item>

        <v-list-item to="/admin/receiving" v-show="can('ord_item_receiving')">
          <v-list-item-icon>
            <v-icon>mdi-package-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Receiving</v-list-item-title>
        </v-list-item>

        <v-list-item to="/admin/in-transit" v-show="can('ord_item_transit')">
          <v-list-item-icon>
            <v-icon>mdi-truck-fast-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>In-transit</v-list-item-title>
        </v-list-item>

        <v-list-item to="/admin/inspection" v-show="can('ord_item_inspection')">
          <v-list-item-icon>
            <v-icon>mdi-check-all</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Inspection</v-list-item-title>
        </v-list-item>

        <v-list-group prepend-icon="mdi-poll" no-action v-show="can('reports')">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Reports</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(rpt, i) in reports" :key="i" :to="rpt.rpt">
              <v-list-item-title>{{ rpt.name }}</v-list-item-title>
              <v-list-item-action>
                  <v-icon>{{ rpt.icon }}</v-icon>
              </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <v-list dense class="pb-0 pt-0" style="background-color:#212121;">
          <v-list-item to="/admin/fabrics" v-show="can('config_fabric')">
            <v-list-item-action>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Configure fabrics</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/admin/configuration"  v-show="can('config_user') || can('config_stylist') || can('config_referral') || can('log')">
              <v-list-item-action>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Configuration</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main :class="isAdmin ? '' : 'container'" :style="!isAdmin ? 'max-width: 95%;' : ''">
      <breadcrumbs v-if="!isAdmin" />
      <router-view />
      <!-- <router-view v-if="appLoaded && isAdmin" />
      <router-view v-else-if="!isAdmin" /> -->
      <restart-dialog :s="addMore" v-if="!isAdmin"></restart-dialog>
      <store-login :show="storeDialog" @loggedin="onStoreLoggedIn"></store-login>
    </v-main>

    <alerts />
    
    <!-- Print iframe -->
    <iframe name="printFrame" id="printFrame" title="printFrame" style="display:none;"></iframe>

  </v-app>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import RestartDialog from '@/components/RestartDialog.vue';
import Alerts from '@/components/Alerts.vue';
import StoreLogin from '@/components/StoreLogin.vue';
import { mapGetters, mapActions } from 'vuex';
import { serverBus } from '@/main';

export default {
  data: () => ({
    addMore       : {
      top: false,
      bottom: true,
      right: false,
      left: true,
      direction: 'top',
      openOnHover: false,
      transition: 'slide-y-reverse-transition',
      active: false
    },

    adminDrawer   : true,
    miniDrawer    : false,
    reports       : [
      { name: 'Sales by Month', icon: '', rpt: '/admin/rpt/salesbymonth' },
      { name: 'Sales by Stylist', icon: '', rpt: '/admin/rpt/salesbystylist' },
      { name: 'Sales by Referral', icon: '', rpt: '/admin/rpt/salesbyreferral' },
      { name: 'Sales by Item', icon: '', rpt: '/admin/rpt/salesbyitem' },
      { name: 'Sales by Store', icon: '', rpt: '/admin/rpt/salesbystore' },
      { name: 'Orders by Store Detail', icon: '', rpt: '/admin/rpt/salesbystoredetail' },
      { name: 'Orders by Status', icon: '', rpt: '/admin/rpt/ordersbystatus' },
      { name: 'No Final Payment', icon: '', rpt: '/admin/rpt/nofinalpayment' },
      { name: 'Journal', icon: '', rpt: '/admin/rpt/journal' },
      { name: 'Cuadre', icon: '', rpt: '/admin/rpt/cuadre' },
      { name: 'Items Pending', icon: '', rpt: '/admin/rpt/itemspending' },
      { name: 'Appointments', icon: '', rpt: '/admin/rpt/appointments' },
      { name: 'Incomplete Orders', icon: '', rpt: '/admin/rpt/incompleteorders' },
      { name: 'TMC vs Retail Price', icon: '', rpt: '/admin/rpt/tmcvsretail' },
    ],

    saveInterval  : null,
  }),
  computed: {
    ...mapGetters({
      appTitle      : 'appTitle',
      cart          : 'cart',
      selectedStore : 'selectedStore',
      pageTitle     : 'admin/pageTitle',
      isLoggedIn    : 'admin/isLoggedIn',
      adminName     : 'admin/userLongName',
      lastLogin     : 'admin/userLastLogin',
      privileges    : 'admin/userPrivileges',
      storeDialog   : 'storeDialog',
      store         : 'store',
    }),
    cartItemQty() {
      return this.cart.reduce((prev, current) => prev + parseInt( current.qty ) ?? 1, 0);
    },
    isAdmin() {
      return this.$route.fullPath.includes('/admin');
    },
  },
  watch: {
    $route (to, from) {
        // Clear save interval
        clearInterval(this.saveInterval);

        if (this.selectedStore() == '') {
          this.updateStore({ 
            prop: 'storeDialog', 
            payload: true 
          });
        }

        if( from.fullPath == '/designer' ) {
            this.updateStore({
              prop    : 'isEdit',
              payload : false
            });
        }

        switch(to.fullPath) {
            case '/cart':
                this.addMore.active = true;
                break;
            case '/checkout':
                this.saveInterval = setInterval(() => serverBus.$emit( 'saveCheckout' ), 20000);
                break;
            default:
                this.addMore.active = false;
                break;
        }
    }
  },
  async created() {
    await this.loadUser();
    serverBus.$on('clearInterval', () => {
      clearInterval(this.saveInterval);
    });
  },
  mounted() {
    console.log('App mounted!');
    if( this.selectedStore() == '' ) {
      this.updateStore({
        prop    : 'storeDialog',
        payload : true
      });
    }

    this.loadStore();

    if( this.selectedStore() == '' ) {
      this.updateStore({
        prop    : 'storeDialog',
        payload : true
      });
    }
  },
  methods: {
    ...mapActions({
      loadStore       : 'loadFromLocalStorage',
      updateStore     : 'updateStore',
      loadUser        : 'admin/loadTokenLocalStorage',
      signOut         : 'admin/signOut',
      setStoreDialog  : 'setStoreDialog',
    }),
    onStoreLoggedIn() {
      this.setStoreDialog( false );
    },
  },
  components: { 
    RestartDialog,
    Breadcrumbs,
    Alerts,
    StoreLogin
  }
};
</script>

<style>
html {
    max-height: 810px;
}
.overflow-hidden {
    overflow: hidden !important;
}
body {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #292b2c;
}

.page-content {
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
}

.wrapper {
    border-radius: 3%;
    border: 2px solid #000;
}

.wrapper:hover {
    background-color: #eeeeee;
}

.active {
    background-color: #eeeeee;
    border: 2px solid #9e9e9e;
}

.hidden {
    display: none;
}

.content img {
    width: 250px;
    height: 250px;
}
</style>
