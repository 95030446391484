import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import darkUnica from "highcharts/themes/dark-unica";
import { _st } from './softech';
import moment from 'moment';

export const serverBus = new Vue();

Vue.config.productionTip = false

Vue.mixin({
	methods: {
		can( privilege ) {
			return store.getters['admin/can']( privilege );
		},
		privMeta( privilege ) {
			if( !this.can( privilege ) )
				return null;

			const found = store.getters['admin/userPrivileges'].find(p => p.privilege == privilege);
			return found?.meta;
		},
		mxShowMessage(text, color, timeout = 6000) {
			serverBus.$emit('alert', { text, color, timeout });
		},
		numbersOnly(e) {
			// Allow: backspace, delete, tab, escape, enter and .
			if (
				[46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
				// Allow: Ctrl/cmd+A
				(e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+C
				(e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+X
				(e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: home, end, left, right
				(e.keyCode >= 35 && e.keyCode <= 39)
			) {
				// let it happen, don't do anything
				return;
			}

			// Ensure that it is a number and stop the keypress
			if (
				(e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
				(e.keyCode < 96 || e.keyCode > 105)
			) {
				e.preventDefault();
			}
		},
		toMoney(v) {
			return _st.isNUE(v) ? _st.toMoney(0) : _st.toMoney(v);
		},
		toNumber(v, p = 0) {
			return _st.isNUE(v) ? _st.toFixedNumber(0, p) : _st.toFixedNumber(v, p);
		},
		toPhoneNum(v) {
			return _st.toPhoneNumber(v);
		},
		numberWithCommas(v) {
			return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		formatDate( date, format = 'YYYY-MM-DD' ) {
			return moment( date ).format( format );
		}
  }
});

darkUnica(Highcharts);
Vue.use(HighchartsVue);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
