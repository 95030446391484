import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import ENUM from '@/inc/enums';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      breadcrumbs() {
        return [
          { text: 'Home', to: '/' }
        ];
      }
    }
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products.vue'),
    meta: {
      breadcrumbs() {
        return [
          { text: 'Home', to: '/' },
          { text: 'Products', to: '/products' }
        ]
      }
    }
  },
  {
    path: '/designer',
    name: 'designer',
    component: () => import('../views/Designer.vue'),
    meta: {
      breadcrumbs() {
        return [
          { text: 'Home', to: '/' },
          { text: 'Products', to: '/products' },
          { text: 'Designer', to: '/designer' }
        ]
      }
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart.vue'),
    meta: {
      breadcrumbs() {
        return [
          { text: 'Home', to: '/' },
          { text: 'Cart', to: '/cart' }
        ]
      }
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/Checkout.vue'),
    meta: {
      breadcrumbs() {
        return [
          { text: 'Home', to: '/' },
          { text: 'Cart', to: '/cart' },
          { text: 'Checkout', to: '/checkout' }
        ]
      }
    }
  },

  // admin
  {
    path: '/admin',
    name: 'Login',
    component: () => import('../views/admin/Login.vue'),
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: () => import('../views/admin/Dashboard.vue'),
    meta: { requiresAuth: true, privileges: ['dashboard'] }
  },
  {
    path: '/admin/orders',
    name: 'Order List',
    component: () => import('../views/admin/OrderList.vue'),
    meta: { requiresAuth: true, privileges: ['order_list'] }
  },
  {
    path: '/admin/receiving',
    name: 'Receiving',
    component: () => import('../views/admin/Receiving.vue'),
    meta: { requiresAuth: true, privileges: ['ord_item_receiving'] }
  },
  {
    path: '/admin/in-transit',
    name: 'In-transit',
    component: () => import('../views/admin/InTransit.vue'),
    meta: { requiresAuth: true, privileges: ['ord_item_transit'] }
  },
  {
    path: '/admin/inspection',
    name: 'Inspection',
    component: () => import('../views/admin/Inspection.vue'),
    meta: { requiresAuth: true, privileges: ['ord_item_inspection'] }
  },
  {
    path: '/admin/order/:orderId',
    name: 'Order Details',
    props: true,
    component: () => import('../views/admin/Order.vue'),
    meta: { requiresAuth: true, privileges: ['order_list'] }
  },
  {
    path: '/admin/fabrics',
    name: 'Fabrics Configuration',
    props: true,
    component: () => import('../views/admin/ConfigFabrics.vue'),
    meta: { requiresAuth: true, privileges: ['config_fabric'] }
  },
  {
    path: '/admin/configuration',
    name: 'Configuration',
    props: true,
    component: () => import('../views/admin/Configuration.vue'),
    meta: { requiresAuth: true, privileges: ['config_user', 'config_stylist', 'config_referral', 'log'] }
  },
  {
    path: '/admin/reports',
    name: 'Reports',
    component: () => import('../views/admin/Reports.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/salesbymonth',
    name: 'Report: Sales by Month',
    props: true,
    component: () => import('../views/admin/reports/RptSalesByMonth.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/salesbystylist',
    name: 'Report: Sales by Stylist',
    props: true,
    component: () => import('../views/admin/reports/RptSalesByStylist.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/salesbyreferral',
    name: 'Report: Sales by Referral',
    props: true,
    component: () => import('../views/admin/reports/RptSalesByReferral.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/salesbyitem',
    name: 'Report: Sales by Item',
    props: true,
    component: () => import('../views/admin/reports/RptSalesByItem.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/salesbystore',
    name: 'Report: Sales by Store',
    props: true,
    component: () => import('../views/admin/reports/RptSalesByStore.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/salesbystoredetail',
    name: 'Report: Sales by Store (Detail)',
    props: true,
    component: () => import('../views/admin/reports/RptSalesByStoreDetail.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/ordersbystatus',
    name: 'Report: Sales by Status',
    props: true,
    component: () => import('../views/admin/reports/RptOrdersByStatus.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/nofinalpayment',
    name: 'Report: No Final Payment',
    props: true,
    component: () => import('../views/admin/reports/RptNoFinalPayment.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/journal',
    name: 'Report: Journal',
    props: true,
    component: () => import('../views/admin/reports/RptJournal.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/cuadre',
    name: 'Report: Cuadre',
    props: true,
    component: () => import('../views/admin/reports/RptCuadre.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/itemspending',
    name: 'Report: Items Pending',
    props: true,
    component: () => import('../views/admin/reports/RptItemsPending.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/appointments',
    name: 'Report: Appointments',
    props: true,
    component: () => import('../views/admin/reports/RptAppointments.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/incompleteorders',
    name: 'Report: Incomplete Orders',
    props: true,
    component: () => import('../views/admin/reports/RptIncompleteOrders.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
  {
    path: '/admin/rpt/tmcvsretail',
    name: 'Report: TMC vs Retail Price',
    props: true,
    component: () => import('../views/admin/reports/RptTmcVsRetail.vue'),
    meta: { requiresAuth: true, privileges: ['reports'] }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {

  // if( from.path == '/' && to.path != '/admin' ) {
  //   next('/admin');
  //   return;
  // }

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const privNeeded = to.matched[0]?.meta.privileges;
	const isAuthenticated = store.getters['admin/isLoggedIn']();
  const appLoaded = store.getters['admin/appState'] === ENUM.LOADED;

  // console.log( requiresAuth, privNeeded, isAuthenticated, appLoaded );

	if( requiresAuth && appLoaded && !isAuthenticated ) {
    next('/admin');
  }
  else if( requiresAuth && appLoaded && privNeeded != undefined ) {
    let allow = privNeeded.some(p => store.getters['admin/can']( p ));
    if( !allow ) {
      next( store.getters['admin/can']( 'dashboard' ) ? '/admin/dashboard' : '/admin/orders' );
    }
  }
  
  store.dispatch('admin/setPageTitle', to.name);
  next();
});

export default router
