import Vue from 'vue'
import Vuex from 'vuex'
import { admin } from './modules/admin.js';
import { orders } from './modules/orders';
import { stores } from './modules/stores';
import { status } from './modules/status';
import { API } from '@/inc/api';
import { serverBus } from '@/main';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appTitle    : 'Design To Measure',
    storeDialog : false,
    storeKey    : '',
    authKey     : '',
    productTypes: null,
    store       : {
      typeId            : null,
      productId         : null,
      productName       : '',
      price             : 0.00,
      qty               : 0,
      modifications     : [],
      fabrics           : [],
      madeToMeasure     : false,
      fit               : '',
      mtmSize           : '',
      availableFabrics  : [],
      createdFabrics    : [],
      cart              : [],
      coupon            : {},
      discount          : {
        amount  : 0,
        note    : ''
      },
      specialCharges    : [],
      isEdit            : false,
      editItemIndex     : null,
      measurements      : {},
      isLcsLogged       : false,
      isClubman         : false,
      store             : '',
      savedCheckout     : null,
    }
  },
  mutations: {
    setStoreDialog(state, payload ) {
      state.storeDialog = payload;
    },
    setProductTypes( state, payload ) {
      state.productTypes = payload;
    },
    setStore( state, payload ) {
      state.store = payload;
    },
    setSelectedStore( state, payload ) {
      state.store.store = payload;
    }
  },
  actions: {
    loadFromLocalStorage({ commit, state }) {
      if( localStorage.getItem('m2m-store') ) {
        commit('setStore', JSON.parse(localStorage.getItem('m2m-store')) );
        state.store.isEdit = false;
        state.store.editItemIndex = null;
      }
    },
    setStoreDialog({ commit }, payload ) {
      commit('setStoreDialog', payload);
    },
    async loadProductTypes({ commit }) {
      try {
        let api = new API();
        let res = await api.get('/prodtype');

        if( res.data.status !== true ) {
          serverBus.$emit('alert', { 
            text: res.data.message, 
            color: res.data.error ? 'error' : 'warning', 
            timeout: 6000 
          });
          return;
        }

        res.data.data.forEach(p => p.active == 1 ? true : false);
        commit('setProductTypes', res.data.data);
        return Promise.resolve();
      }
      catch(error) {
        return Promise.reject(error);
      }
    },
    async loadProductTypesAdmin({ commit }) {
      try {
        let api = new API();
        let res = await api.get('/admin/prodtype');

        if( res.data.status !== true ) {
          serverBus.$emit('alert', { 
            text: res.data.message, 
            color: res.data.error ? 'error' : 'warning', 
            timeout: 6000 
          });
          return;
        }

        res.data.data.forEach(p => p.active == 1 ? true : false);
        commit('setProductTypes', res.data.data);
        return Promise.resolve();
      }
      catch( error ) {
        return Promise.reject( error );
      }
    },
    setProductTypes({ commit }, payload) {
      commit('setProductTypes', payload);
    },
    clearStore({ commit }) {
      commit('setStore', {
        typeId            : null,
        productId         : null,
        productName       : '',
        price             : 0.00,
        qty               : 0,
        modifications     : [],
        fabrics           : [],
        madeToMeasure     : false,
        fit               : 'Slim',
        availableFabrics  : [],
        createdFabrics    : [],
        cart              : [],
        coupon            : {},
        discount          : {
          amount  : 0,
          note    : ''
        },
        specialCharges    : [],
        isEdit            : false,
        editItemIndex     : null,
        measurements      : {},
        isLcsLogged       : false,
        isClubman         : false,
        store             : '',
        savedCheckout     : null,
      });
    },
    updateStore({ state, dispatch }, { prop, payload }) {
      state.store[prop] = payload;
      dispatch('saveChanges');
    },
    saveChanges({ state }) {
      console.log('Store changed!');
      localStorage.setItem('m2m-store', JSON.stringify(state.store));
    }
  },
  getters: {
    appTitle      : (state) => state.store.isClubman ?? false ? 'Clubman Create' : state.appTitle,
    storeDialog   : (state) => state.storeDialog,
    storeKey      : (state) => state.storeKey,
    authKey       : (state) => state.authKey,
    productTypes  : (state) => state.productTypes,
    store         : (state) => state.store,
    cart          : (state) => state.store.cart,
    selectedStore : () => () => {
      if( localStorage.getItem('m2m-selected-store') ) {
        let obj = JSON.parse(localStorage.getItem('m2m-selected-store'));

        let expirationTime = 24 * 60 * 60 * 1000; // ms
        if( ((new Date) - obj.timeStamp) < expirationTime )
          return obj.value;
        else {
          localStorage.removeItem('m2m-selected-store');
          return '';
        }

      }

      return '';
    },
    selectedStoreKey: () => () => {
      if( localStorage.getItem('m2m-selected-store') ) {
        let obj = JSON.parse(localStorage.getItem('m2m-selected-store'));

        let expirationTime = 24 * 60 * 60 * 1000; // ms
        if( ((new Date) - obj.timeStamp) < expirationTime )
          return obj.key;
        else {
          localStorage.removeItem('m2m-selected-store');
          return '';
        }

      }

      return '';
    }
  },
  modules: {
    admin,
    orders,
    stores,
    status
  }
})
